import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { installationService } from './services/installation.service';
import MainRoutes from './components/MainRoutes';
import StyledCenteredLoader from './components/1-library/StyledCenteredLoader';
import {
  getInstallation,
  getIsInstallationEnabled,
  updateInstallation,
  updateIsInstallationEnabled
} from './features/tenantSlice';
import { endOfDay, isAfter } from 'date-fns';
import './css/main.css';
import { profileService } from './services/profile.service';
import {
  updateProfile,
  updateLoadingProfile,
  getProfile,
  getLoadingProfile
} from './features/authSlice';

const App = () => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const defaultView = 'combinedDash';
  const [loading, setLoading] = useState(true);
  // const [beProfile, setBeProfile] = useState({});
  const beProfile = useSelector(getProfile);
  const loadingProfile = useSelector(getLoadingProfile);
  const fb = useSelector(state => state.firebase);
  const profile = useSelector(state => state.firebase.profile);
  const isInitializing = useSelector(state => state.firebase?.isInitializing);
  const profileIsEmpty = useSelector(state => state.firebase?.profile?.isEmpty);
  const profileIsLoaded = useSelector(state => state.firebase?.profile?.isLoaded);
  const installation = useSelector(getInstallation);
  const isInstallationEnabled = useSelector(getIsInstallationEnabled);

  const fetchProfile = async () => {
    const profileObj = await profileService.getProfile(fb.auth?.uid);
    dispatch(updateProfile({ ...(profileObj || {}) }));
  };

  const getInstallationForTenant = async () => {
    const installationObj = await installationService.getInstallation(
      beProfile.tenantId,
      beProfile.installationId
    );
    installationObj.view = 'combinedDash'; // ToDo: fix this
    dispatch(updateInstallation({ ...installationObj }));
  };

  useFirestoreConnect([
    { collection: 'users' },
    { collection: 'tenants' },
    { collection: 'installations' },
    { collection: 'precincts' },
    { collection: 'zones' },
    { collection: 'probes' },
    { collection: 'devices' },
    { collection: 'roles' }
  ]);

  // const installations = useSelector((state) => state.firestore?.ordered.installations);
  // const installation = installations?.find((e) => e.id === profile.installation);

  useEffect(() => {
    console.log('====== we have the installation', installation);
    if (!installation?.finishTime) return;
    const convertedFinishTime = endOfDay(new Date(+installation.finishTime));
    const convertedToday = endOfDay(new Date());
    dispatch(
      updateIsInstallationEnabled(
        isAfter(convertedFinishTime, convertedToday) && !installation.showEventsPage
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installation]);

  useEffect(() => {
    // we need to first get the profile from the DB and load the thing from firestore as well
    if (profile.isEmpty && fb.auth?.uid) {
      fetchProfile()
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          dispatch(updateLoadingProfile(false));
        });
    } else {
      dispatch(updateLoadingProfile(false));
    }
    
    if (profileIsEmpty && profileIsLoaded) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, profileIsEmpty, profileIsLoaded]);

  useEffect(() => {
    if (beProfile?.tenantId) {
      // need to load the installation here
      // and check it to see if it's enabled and then redirect to the login page if it's not
      // need to make it a function since useEffect doesn't like async
      getInstallationForTenant()
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beProfile])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {!isInitializing && !loading && !loadingProfile ? (
          <MainRoutes
            role={profile?.role ?? beProfile?.role}
            view={defaultView}
            enabled={isInstallationEnabled ?? true}
            pathname={pathname}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              height: '100vh',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <StyledCenteredLoader />
          </div>
        )}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
