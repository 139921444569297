import firebase from 'firebase/compat';
import { firebase as fbConfig } from '../config';
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import dataReducer from './reducers';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { btleUniquesSlice } from '../features/btle-uniques/btleSlice';
import tenantSliceReducer from '../features/tenantSlice';
import authSliceReducer from '../features/authSlice';
import probesSliceReducer from '../features/probesSlice';

firebase.initializeApp(fbConfig);
firebase.firestore();

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  data: dataReducer,
  btleUniques: btleUniquesSlice.reducer,
  tenant: tenantSliceReducer,
  auth: authSliceReducer,
  probes: probesSliceReducer
});

const customizedMiddleware = [
  ...getDefaultMiddleware({
    serializableCheck: false
  })
];

const initialState = {
  firebase: { authError: null }
};
// export const store = createStore(rootReducer, initialState);
export const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
  preloadedState: initialState,
  devTools: true,
  reducerPath: ['btleUniques']
});
