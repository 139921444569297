import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, CircularProgress, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getProbes } from '../../features/probesSlice';
import ColumnChart from '../highCharts/ColumnChart';
import { BaNodeService } from '../../services/ba-node.service';
import { formatDailyUniquesData, mergeUniques } from './dailyUniques.helpers';
import { calcFromTime } from '../../utils/time.helpers';
import { useDispatch } from 'react-redux';
import { updateBtleUniques } from '../../features/btle-uniques/btleSlice';

const baNodeService = new BaNodeService();

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const DailyUniquesGraph = React.memo(
  ({ className, installation, locations, selectedDates, time, ...rest }) => {
    const probes = useSelector(getProbes);
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [btleUniquesMinuteData, setBtleUniquesMinuteData] = useState([]);
    const [btleUniquesHourData, setBtleUniquesHourData] = useState([]);
    const [btleUniquesMax, setBtleUniquesMax] = useState(0);
    const [enableGetData, setEnableGetData] = useState(true);
    // const [zoom, setZoom] = useState([0, 0]);
    const [btleUniquesData, setBtleUniquesData] = useState({});
    const [fromTime, setFromTime] = useState(null);
    const [gettingBtleData, setGettingBtleData] = useState(false);

    const dispatch = useDispatch();

    const getFifteenMinuteAverage = uniquesDataArray => {
      const latestCount = uniquesDataArray?.at(-1);
      if (!latestCount) return '';
      // const fifteenMinuteStart = addMinutes(new Date(latestCount.timestamp), -15).getTime();
      // first we get the index 15 spots from the end
      let lastIndex = uniquesDataArray?.length - 15;
      if (lastIndex < 0) lastIndex = 0;
      const counts = uniquesDataArray.slice(lastIndex);
      const total = counts.reduce((curr, c) => (curr += c.count), 0);
      return Math.round(total / counts.length);
    };

    const getBtleUniques = async () => {
      if (!fromTime) return;
      const locationIds = locations
        .flat(2)
        .map(l => l?.location?.id)
        .filter(l => l !== undefined);

      if (!locationIds.length || !probes.length) return;
      const result = await baNodeService.getBtleCounts(
        locationIds,
        [fromTime, time],
        probes
      );
      // need to update the keys in the btleUniquesData
      const keys = Object.keys(result);
      let tempUniquesData = { ...btleUniquesData };
      keys.forEach(key => {
        let data = tempUniquesData[key] || [];
        const averagedUniqueVal = getFifteenMinuteAverage(data);

        const unique = {};
        unique[key] = { count: averagedUniqueVal };
        dispatch(updateBtleUniques({ unique }));
        if (!data.length) {
          data = [...result[key]];
        } else {
          const tempCount = data || [];
          data = mergeUniques(tempCount, result[key], 'timestamp');
        }
        tempUniquesData[key] = [...data];
      });
      setBtleUniquesData({ ...tempUniquesData });
    };

    useEffect(() => {
      if (!Object.keys(btleUniquesData).length) {
        setGettingBtleData(false);
        return;
      }
      const locationsFlat = locations.flat(2);
      const { maxUniques, hourData, minuteData } = formatDailyUniquesData(
        locationsFlat,
        btleUniquesData
      );

      setBtleUniquesHourData(hourData);
      setBtleUniquesMinuteData({ series: minuteData });
      setBtleUniquesMax(maxUniques);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [btleUniquesData, locations]);

    useEffect(() => {
      if (!enableGetData || !probes?.length || gettingBtleData) return;

      const newFromTime = calcFromTime(installation, time, fromTime);
      if (newFromTime) setFromTime(newFromTime);
      setGettingBtleData(true);
      getBtleUniques().finally(() => {
        setGettingBtleData(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, enableGetData, probes]);

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title={'Unique Count'} />
        <CardContent>
          {btleUniquesMinuteData.series ? (
            <ColumnChart
              name={`${locations[0].type}: ${locations[0].location.name}`}
              count={btleUniquesHourData}
              drilldown={btleUniquesMinuteData}
              capacity={btleUniquesMax}
              setEnableGetData={setEnableGetData}
              allowPointDrilldown
            />
          ) : (
            <CircularProgress />
          )}
        </CardContent>
      </Card>
    );
  }
);

DailyUniquesGraph.propTypes = {
  className: PropTypes.string,
  installation: PropTypes.object,
  locations: PropTypes.array,
  selectedDates: PropTypes.array,
  time: PropTypes.number
};

export default DailyUniquesGraph;
