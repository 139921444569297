import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import LiveGauge from './LiveGauge';
import LiveGaugeHeader from './LiveGaugeHeader';

const useStyles = makeStyles(() => ({
  root: {
    // height: '100%'
  }
}));

const LiveGauges = React.memo(({ className, locations, age, time, ...rest }) => {
  const [locationsList, setLocationsList] = useState([]);
  const [firstLocation, setFirstLocation] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (locations?.length > 1 && Array.isArray(locations[1])) {
      setFirstLocation({ ...locations[0] });
      setLocationsList([...locations.slice(1)]);
    } else if (locations?.length) {
      setFirstLocation(null);
      setLocationsList([...locations]);
    }
  }, [locations]);

  const liveGauges = locationsList.map(
    l =>
      l instanceof Array && (
        <LiveGauges
          className={className}
          locations={l}
          age={age}
          time={time}
          key={l[0]?.location?.id}
        />
      )
  );

  const liveGaugeList = locationsList?.map(
    l =>
      l.location !== undefined && (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={l?.location?.id}>
          <LiveGauge className={className} location={l} age={age} time={time} />
        </Grid>
      )
  );

  return (
    <>
      {firstLocation && locationsList.length > 0 && (
        <Card className={clsx(classes.root, className)} {...rest}>
          <LiveGaugeHeader className={className} location={firstLocation} age={age} time={time} />
          <CardContent>{liveGauges}</CardContent>
        </Card>
      )}
      {!firstLocation && locationsList.length > 0 && (
        <Grid container spacing={1}>
          {liveGaugeList}
        </Grid>
      )}
    </>
  );
});

LiveGauges.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array,
  age: PropTypes.number,
  time: PropTypes.number
};

export default LiveGauges;
