import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: colors.blueGrey[900],
      default: '#121212',
      paper: colors.blueGrey[800],
      gradient: 'linear-gradient(0deg, #263238, #546E7A)',
      highCharts: {
        linearGradient: {
          x1: 0, x2: 0, y1: 0, y2: 1
        },
        stops: [
          [0, colors.blueGrey[600]],
          [0.5, colors.blueGrey[800]],
          [1, colors.blueGrey[900]]
        ]
      }
    },
    primary: {
      light: colors.blue[700],
      main: colors.blue[500],
      dark: colors.blue[300],
      contrastText: '#fff'
    },
    secondary: {
      light: colors.purple[700],
      main: colors.purple[500],
      dark: colors.purple[300],
      contrastText: '#000'
    },
    type: 'dark'
  },
  shadows,
  typography
});

export default theme;
