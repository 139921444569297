import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tenant: {},
  installations: [],
  installation: {},
  isInstallationEnabled: true
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    updateTenant: (state, action) => {
      state.tenant = { ...state.tenant, ...(action.payload || {}) };
    },
    updateInstallations: (state, action) => {
      state.installations = [...state.installations, ...(action.payload || [])];
    },
    updateInstallation: (state, action) => {
      state.installation = { ...state.installation, ...(action.payload || {}) };
    },
    updateIsInstallationEnabled: (state, action) => {
      state.isInstallationEnabled = action.payload;
    }
  }
});

export const {
  updateBtleUniques,
  updateInstallations,
  updateInstallation,
  updateIsInstallationEnabled
} = tenantSlice.actions;

// TODO: need to get this in the component, at the moment, not coming through
export const getTenant = state => state.tenant?.tenant;
export const getInstallations = state => state.tenant?.installations;
export const getInstallation = state => state.tenant?.installation;
export const getIsInstallationEnabled = state => state.tenant?.isInstallationEnabled;
export const getDefaultNavigateTo = state => {
  return state.tenant.isInstallationEnabled ? '/liveDash' : 'event-dash';
};

export default tenantSlice.reducer;
