import React, { useEffect, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Container, Grid, makeStyles, Modal, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import useGet from '../../hooks/useGet';
import Clock from '../../components/Clock';
import LiveGaugesWrapper from '../../components/live-gauges/LiveGaugesWrapper';
import BtleUniquesWrapper from '../../components/btle-uniques/BtleUniqesWrapper';
import DailyUniquesGraph from '../../components/daily-uniques-graph/DailyUniquesGraph';
import MinuteGraphs from '../../components/MinuteGraph/MinuteGraphs';
import Title from './Title';
import { apiUrls, TOP_DASH_FILTER_LEVEL } from '../../config/constants';
import { getProfile } from '../../features/authSlice';
import { updateProbes } from '../../features/probesSlice';
import { baNodeService } from '../../services/ba-node.service';
import { tenantService } from '../../services/tenant.service';

const tenantsUrl = apiUrls['tenants'];

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    background: theme.palette.background.gradient
  }
}));

const PRECINCT_OBJ = {
  id: 'allPrecincts',
  name: 'allPrecincts',
  zones: {}
};

const DashboardView = () => {
  const dispatch = useDispatch();
  const [installation, setInstallation] = useState({ precincts: {} });
  const [selectedPrecinct, setSelectedPrecinct] = useState({});
  const [selectedZone, setSelectedZone] = useState({
    id: 'all',
    name: 'all'
  });
  const [time, setTime] = useState(new Date());
  const [displayTime, setDisplayTime] = useState(new Date());
  const [tick, setTick] = useState(0);
  const [currentMinute, setCurrentMinute] = useState(
    Math.floor(new Date().getTime() / 60000) * 60000
  );
  const [delayedCurrentMinute, setDelayedCurrentMinute] = useState(
    Math.floor(new Date().getTime() / 60000) * 60000
  );
  const beProfile = useSelector(getProfile);
  // const installationUrl = `${tenantsUrl}/${beProfile.tenantId}/installations/${beProfile.installationId}`;
  const installationUrl = `${tenantsUrl}/${beProfile.tenantId}/installations/${beProfile.installationId}`;
  const [, /* zoom */ setZoom] = useState([0, 0]);
  const [currentLocations, setCurrentLocations] = useState([]);
  const [precincts, setPrecincts] = useState([]);
  const [getPrecinctsIsLoading, setGetPrecinctsIsLoading] = useState(false);
  const [getTenantData, getTenantError /* getTenantIsLoading */, , getTenantSetUrl] = useGet();
  const [
    getInstallationData,
    getInstallationError,
    getInstallationIsLoading,
    getInstallationSetUrl
  ] = useGet();
  const [getZonesData, getZonesError, getZonesIsLoading, getZonesSetUrl] = useGet();
  const classes = useStyles();
  const subtitle = getTenantData?.name ?? '';

  useEffect(() => {
    const intervalID = setInterval(() => setDisplayTime(new Date()), 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    const intervalID = setInterval(
      () => setTime(new Date()),
      3000 // 1 second timer
    );
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const getPrecincts = useCallback(async () => {
    if (!beProfile?.installationId) return;
    const data = await tenantService.getTenantPrecincts(beProfile.tenantId, beProfile.installationId);
    console.log('====== precincts loaded from python', data)
    setPrecincts([...data]);
  }, [beProfile]);
  const getProbes = useCallback(async () => {
    if (!installation) return;
    const data = await baNodeService.getProbesByInstallationIds([installation?.id]);
    dispatch(updateProbes(data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installation]);

  useEffect(() => {
    if (installation.loaded && installation.topDashFilterLevel === TOP_DASH_FILTER_LEVEL.Precinct) {
      setSelectedPrecinct({ ...PRECINCT_OBJ})
    }
  }, [installation]);

  useEffect(() => {
    getTenantSetUrl(`${tenantsUrl}/${beProfile.tenantId}`);
    getInstallationSetUrl(installationUrl);
    getZonesSetUrl(`${installationUrl}/zones`);
  }, [
    getTenantSetUrl,
    getInstallationSetUrl,
    getZonesSetUrl,
    beProfile.tenantId,
    installationUrl,
  ]);

  useEffect(() => {
    if (precincts.length === 0 && !getPrecinctsIsLoading) {
      setGetPrecinctsIsLoading(true);
      getPrecincts()
      .catch(e => console.error(e))
      .finally(() => setGetPrecinctsIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !getInstallationIsLoading &&
      getInstallationData &&
      precincts.length > 0 &&
      !getPrecinctsIsLoading &&
      !getZonesIsLoading &&
      getZonesData
    ) {
      const zonesWithProbes = Object.entries(getZonesData).reduce((result, [id, zone]) => {
        return (zone.probeId && zone.probeId && zone.show) !== ''
          ? [...result, { ...zone }]
          : result;
      }, []);
      console.log('===== precincts', precincts)
      precincts.forEach(precinct => {
        precinct.capacity = precinct.zones.reduce(
          (capacity, zoneWithProbe) => capacity + parseFloat(zoneWithProbe.capacity),
          0
        )
        precinct.area = precinct.zones.reduce(
          (area, zoneWithProbe) => area + parseFloat(zoneWithProbe.area),
          0
        )
      });
      console.log('====== precincts', precincts)
      setInstallation({
        ...getInstallationData,
        capacity: zonesWithProbes.reduce((result, zone) => result + parseFloat(zone.capacity), 0),
        area: zonesWithProbes.reduce((result, zone) => result + parseFloat(zone.area), 0),
        precincts: precincts.reduce(
          (precinctResult, precinct) => ({
            ...precinctResult,
            [precinct.id]: precinct
          }),
          {}
        ),
        loaded: true
      }); // setInstallation
      setSelectedPrecinct(
        precincts.length === 1
          ? {
              id: precincts[0].id,
              name: precincts[0].name,
              area: precincts[0].area,
              capacity: precincts[0].capacity,
              zones: precincts[0].zones
                .reduce(
                  (zones, zone) => ({
                    ...zones,
                    [zone.id]: zone
                  }),
                  {}
                )
            }
          : getInstallationData.topDashFilterLevel === TOP_DASH_FILTER_LEVEL.Event ? {
            ...PRECINCT_OBJ
          } : {
              id: 'allZones',
              name: 'allZones',
              zones: {}
            }
      ); // setSelectedPrecinct
      console.log('====== selectedPrecinct', selectedPrecinct)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getInstallationIsLoading,
    getInstallationData,
    getPrecinctsIsLoading,
    getZonesIsLoading,
    getZonesData,
    precincts
  ]);

  useEffect(() => {
    if (installation.loaded) {
      const timeToSet = Math.floor(time.getTime() / 60000) * 60000;
      setTick(Math.floor(time.getTime() / 1000) * 1000);
      setCurrentMinute(timeToSet);
      // need to wait for 1 second longer since there is an issue where there
      // is no data when the function runs on the stroke of the 15 mins
      setTimeout(() => setDelayedCurrentMinute(timeToSet), 1000);
    }
  }, [time, installation.loaded, installationUrl]);

  useEffect(() => {
    if (!selectedPrecinct.id) return
    if (installation.loaded) {
      getProbes().catch(e => console.error(e));

      if (selectedPrecinct.id === 'allZones') {
        setCurrentLocations([
          { type: 'Installation', location: installation },
          ...Object.values(installation.precincts).map(precinct => [
            { type: 'Precinct', location: precinct },
            Object.values(precinct.zones)
              .filter(zone => zone.show)
              .map(zone => ({ type: 'Zone', location: zone }))
          ])
        ]);
      } else if (selectedPrecinct.id === 'allPrecincts') {
        setCurrentLocations([
          { type: 'Installation', location: installation },
          Object.values(installation.precincts).map(precinct => ({
            type: 'Precinct',
            location: precinct
          }))
        ]);
      } else if (selectedZone.id === 'all') {
        setCurrentLocations([
          { type: 'Precinct', location: selectedPrecinct },
          Object.values(selectedPrecinct.zones)
            .filter(zone => zone.show)
            .map(zone => ({ type: 'Zone', location: zone }))
        ]);
      } else {
        // if (!'allZones' && !'allPrecincts' && !'all')
        setCurrentLocations([{ type: 'Zone', location: selectedZone }]);
      } //endif (!'allZones' && !'allPrecincts' && !'all')
    } // endif (installation.loaded)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installation, selectedPrecinct, selectedZone]);

  useEffect(() => {
    let tempZone = {
      name: 'all',
      id: 'all'
    };
    if (!selectedPrecinct?.id) return;
    console.log('====== selectedPrecinct', selectedPrecinct)
    if (
      selectedPrecinct.id !== 'allZones' &&
      selectedPrecinct.id !== 'allPrecincts' &&
      Object.keys(selectedPrecinct.zones).length === 1
    ) {
      const currentZone = Object.values(selectedPrecinct.zones)[0];
      tempZone = {
        name: currentZone.name,
        id: currentZone.id,
        area: currentZone.area,
        capacity: currentZone.capacity
      };
    }
    setSelectedZone(tempZone);
  }, [selectedPrecinct]);

  if (!beProfile.installationId || getInstallationError) {
    return <Navigate to="/account" />;
  } else {
    return (
      <Page className={classes.root} title="Live Count">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item lg={8} md={6} sm={7} xs={12}>
              <Title
                title="Live counter"
                subtitle={subtitle}
                installation={installation}
                selectedPrecinct={selectedPrecinct}
                setSelectedPrecinct={setSelectedPrecinct}
                selectedZone={selectedZone}
                setSelectedZone={setSelectedZone}
                isLoading={getInstallationIsLoading || getZonesIsLoading}
                className={classes.card}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={5} xs={12}>
              <Clock time={displayTime.toLocaleString()} className={classes.card} />
            </Grid>
            {installation.loaded && (
              <Grid item xs={12}>
                <LiveGaugesWrapper
                  installation={installation}
                  className={classes.card}
                  locations={currentLocations}
                  age={installation.wifiAge}
                  time={tick}
                  currentMinute={delayedCurrentMinute}
                />
                <Grid item xs={12}>
                  <MinuteGraphs
                    className={classes.card}
                    installation={installation}
                    locations={currentLocations}
                    currentLocations={currentLocations}
                    age={installation.wifiAge}
                    time={currentMinute}
                    setZoom={setZoom}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BtleUniquesWrapper
                    className={classes.card}
                    classes={classes}
                    installation={installation}
                    locations={currentLocations}
                    time={currentMinute}
                    // zoom={zoom}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DailyUniquesGraph
                    className={classes.card}
                    installation={installation}
                    locations={currentLocations}
                    time={currentMinute}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
        <Modal open={Boolean(getTenantError || getInstallationError)}>
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {getTenantError && 'getTenantError'}
              {getInstallationError && 'getInstallationError'}
              {getZonesError && 'getZonesError'}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {getTenantError && getTenantError.response.statusText}
              {getInstallationError && getInstallationError.response.statusText}
              {getZonesError && getZonesError.response.statusText}
            </Typography>
          </Box>
        </Modal>
      </Page>
    );
  }
};

DashboardView.propTypes = {};

export default DashboardView;
