// Firebase config
export const API_KEY = process.env.REACT_APP_API_KEY;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const DATABASE_URL = process.env.REACT_APP_DATABASE_URL;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;

// Auth0 client
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const IOTHUB_FUNCTION_URL = process.env.REACT_APP_IOTHUB_FUNCTION_URL;
export const REST_BACKEND_URL = process.env.REACT_APP_REST_BACKEND_URL;
export const REST_V2_BACKEND_URL = process.env.REACT_APP_REST_V2_BACKEND_URL;
export const REST_V2_BACKEND_PYTHON_URL = process.env.REACT_APP_REST_V2_BACKEND_PYTHON_URL;
export const REST_V2_API_KEY = process.env.REACT_APP_REST_V2_API_KEY;
export const LOCATIONS_URL = 'location';
export const REPORTS_API = process.env.REACT_APP_REPORTS_API;

// TODO: move to the BE database so it can be updated, but it's ok here for now
export const TOP_DASH_FILTER_LEVEL = {
  Event: 'EVENT',
  Precinct: 'PRECINCT',
  Zone: 'ZONE'
}

export const apiUrls = {
  tenants: `${REST_BACKEND_URL}/tenants`,
  tenants_v2: `${REST_V2_BACKEND_PYTHON_URL}/tenants`,
  eventsUrl: `${REST_V2_BACKEND_URL}/events`,
  eventsUrlPy: `${REST_V2_BACKEND_PYTHON_URL}/events`,
  countToday: `${REST_V2_BACKEND_PYTHON_URL}/events/live-counts`,
  probes: `${REST_BACKEND_URL}/probes`
};
