import React from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
}));

const Title = React.memo(({
  className,
  title,
  subtitle,
  installation,
  selectedPrecinct,
  setSelectedPrecinct,
  selectedZone,
  setSelectedZone,
  setTitle,
  isLoading = false,
  ...rest
}) => {
  const classes = useStyles();

  const handleSelectPrecinct = (e) => {
    if (
      e.target.value === 'none' 
      || e.target.value === 'all'
      || e.target.value === 'allZones'
      || e.target.value === 'allPrecincts'
    ) {
      setSelectedPrecinct({
        id: e.target.value,
        name: e.target.value,
        zones: {}
      });
      setTitle(installation.name);
    } else {
      setSelectedPrecinct(installation.precincts[e.target.value]);
      setSelectedZone({
        id: 'none',
        name: 'none'
      });
      setTitle(installation.precincts[e.target.value].name)
    }
  }

  const handleSelectZone = (e) => {
    if (
      e.target.value === 'none' 
      || e.target.value === 'allPrecinct'
      || e.target.value === 'onlyZones'
    ) {
      setSelectedZone({
        id: e.target.value,
        name: e.target.value
      });
      setTitle(selectedPrecinct.name);
    } else {
      setSelectedZone(selectedPrecinct.zones[e.target.value]);
      setTitle(selectedPrecinct.zones[e.target.value].name);
    }
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h1">
              {title}
            </Typography>
            <Typography variant="h2">
              {subtitle}
            </Typography>
          </Grid>
          {isLoading ? (
            <Grid item xs={12} sm={4}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item xs={12} sm={8}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                  <TableCell>
                      Installation:
                    </TableCell>
                    <TableCell>
                      {installation?.name || 'Select an Installation in the Account menu'}
                    </TableCell>
                  </TableRow>
                  {installation?.name && (
                    <>
                      <TableRow>
                        <TableCell>
                          Precinct:
                        </TableCell>
                        <TableCell>
                          {Object.keys(installation.precincts).length === 1 ? (
                            Object.values(installation.precincts)[0].name
                          ) : (
                            <Select
                              value={selectedPrecinct.id}
                              onChange={handleSelectPrecinct}
                            >
                              <MenuItem value='none'>Installation</MenuItem>
                              <MenuItem value='all'>All precincts and zones</MenuItem>
                              <MenuItem value='allZones'>All zones without precincts</MenuItem>
                              <MenuItem value='allPrecincts'>All precincts without zones</MenuItem>
                              {Object.entries(installation.precincts).map(([precinctId, precinct]) => (
                                <MenuItem key={precinctId} value={precinctId}>
                                  {precinct.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </TableCell>
                      </TableRow>
                      {selectedPrecinct.id !== 'none'
                        && selectedPrecinct.id !== 'all'
                        && selectedPrecinct.id !== 'allZones'
                        && selectedPrecinct.id !== 'allPrecincts' 
                        && (
                        <TableRow>
                          <TableCell>
                            Zone:
                          </TableCell>
                          <TableCell>
                            {Object.keys(selectedPrecinct.zones).length === 1 ? (
                              Object.values(selectedPrecinct.zones)[0].name
                            ) : (
                              <Select
                                value={selectedZone.id}
                                onChange={handleSelectZone}
                              >
                                <MenuItem value='none'>None</MenuItem>
                                <MenuItem value='allPrecinct'>All with precinct</MenuItem>
                                <MenuItem value='onlyZones'>All without precinct</MenuItem>
                                {Object.values(selectedPrecinct.zones).map((zone) => (
                                  <MenuItem key={zone.id} value={zone.id}>
                                    {zone.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
});

Title.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  installation: PropTypes.object,
  selectedPrecinct: PropTypes.object,
  setSelectedPrecinct: PropTypes.func,
  selectedZone: PropTypes.object,
  setSelectedZone: PropTypes.func,
  setTitle: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Title;
