import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {},
  loadingProfile: true
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      const displayName = action.payload?.displayName || '';
      const data = action.payload
      if (displayName) {
        const names = displayName.split(' ');
        data.initials = names?.at(0)?.toUpperCase() +
          (names.length > 1 ? names?.[names.length - 1]?.substring(0, 1) : '');
      }
      state.profile = { ...(data || {}) };
    },
    updateLoadingProfile: (state, action) => {
      state.loadingProfile = action.payload;
    }
  }
});

export const { updateProfile, updateLoadingProfile } = authSlice.actions;

export const getProfile = state => state.auth?.profile;
export const getLoadingProfile = state => state.auth?.loadingProfile;

export default authSlice.reducer;
