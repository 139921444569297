import Highcharts from 'highcharts'

export const config = {
    chart: {
        zoomType: 'x',
        borderRadius: 10
    },
    title: {
        text: ''
    },
    subtitle: {
        text:
            document.ontouchstart === undefined
                ? 'Click and drag in the plot area to zoom in'
                : 'Pinch the chart to zoom in'
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%H:%M'
        }
    },
    yAxis: {
        title: {
            text: 'Count'
        }
    },
    time: {
        timezoneOffset: new Date().getTimezoneOffset()
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        area: {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [
                        1,
                        Highcharts.color(Highcharts.getOptions().colors[0])
                            .setOpacity(0)
                            .get('rgba')
                    ]
                ]
            },
            marker: {
                radius: 2
            },
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1
                }
            },
            threshold: null
        }
    },
    credits: {
        enabled: false
    },
    series: [
        {
            type: 'line',
            name: '',
            data: []
        }
    ],
    boost: {
        useGPUTranslations: true
    },
    tooltip: {
        dateTimeLabelFormats: {
            day: '%H:%M',
            hour: '%H:%M',
            minute: '%H:%M'
        }
    },
    colors: [
        'rgba( 0,   154, 253, 0.9 )', // bright blue
        'rgba( 253, 99,  0,   0.9 )', // bright orange
        'rgba( 40,  40,  56,  0.9 )', // dark
        'rgba( 253, 0,   154, 0.9 )', // bright pink
        'rgba( 154, 253, 0,   0.9 )', // bright green
        'rgba( 145, 44,  138, 0.9 )', // mid purple
        'rgba( 45,  47,  238, 0.9 )', // mid blue
        'rgba( 177, 69,  0,   0.9 )', // dark orange
        'rgba( 140, 140, 156, 0.9 )', // mid
        'rgba( 238, 46,  47,  0.9 )', // mid red
        'rgba( 44,  145, 51,  0.9 )', // mid green
        'rgba( 103, 16,  192, 0.9 )' // dark purple
    ],
    exporting: {
        csv: {
            dateFormat: '%H:%M:%S'
        }
    }
}

export { config as timeSeriesConfig }
