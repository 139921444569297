import React, { useEffect, useState } from 'react';
// Import Highcharts
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { timeSeriesConfig } from './timeSeries.config';

exporting(Highcharts);
exportData(Highcharts);

const staticOptions = { ...timeSeriesConfig };

const TimeSeriesZoomable = React.memo(({ name, count, capacity, setZoom }) => {
  const [options, setOptions] = useState(staticOptions);
  const theme = useTheme();

  useEffect(() => {
    staticOptions.chart.backgroundColor = theme.palette.background.highCharts;
    staticOptions.title.text = name;
    staticOptions.title.style = { color: theme.palette.text.primary };
    staticOptions.subtitle.style = { color: theme.palette.text.secondary };
    staticOptions.legend.itemStyle = { color: theme.palette.text.primary };
    staticOptions.yAxis.max = capacity;
    staticOptions.yAxis.labels = {
      ...staticOptions.yAxis.labels,
      style: { color: theme.palette.text.primary }
    };
    staticOptions.xAxis.labels = {
      ...staticOptions.xAxis.labels,
      style: { color: theme.palette.text.primary }
    };
    staticOptions.yAxis.title = {
      ...staticOptions.yAxis.title,
      style: { color: theme.palette.text.primary }
    };
    staticOptions.xAxis.title = {
      ...staticOptions.xAxis.title,
      style: { color: theme.palette.text.primary }
    };
  }, [capacity, name, theme.palette]);

  useEffect(() => {
    // console.log('======== found count', count);
    const handleZoom = event => {
      if (event.type === 'selection') {
        if (event.resetSelection) {
          setZoom([0, 0]);
        } else if (event.xAxis && event.xAxis.length) {
          setZoom([Math.ceil(event.xAxis[0].min), Math.floor(event.xAxis[0].max)]);
        }
      }
    };
    setOptions({
      ...staticOptions,
      title: {
        ...staticOptions.title,
        text: name
      },
      series: count,
      yAxis: {
        ...staticOptions.yAxis,
        max: capacity
      },
      chart: {
        ...staticOptions.chart,
        events: {
          selection: handleZoom
        }
      }
    });
  }, [name, count, capacity, setZoom]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
});

TimeSeriesZoomable.propTypes = {
  name: PropTypes.string,
  count: PropTypes.array,
  capacity: PropTypes.number,
  setZoom: PropTypes.func
};

export default TimeSeriesZoomable;
