import Highcharts from 'highcharts';

export const staticOptions = {
  chart: {
    type: 'solidgauge',
    height: '70%',
    borderRadius: 10
  },
  title: {
    text: null
  },
  pane: {
    center: ['50%', '90%'],
    size: '170%',
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
      innerRadius: '60%',
      outerRadius: '100%',
      shape: 'arc'
    }
  },

  exporting: {
    enabled: false
  },

  tooltip: {
    enabled: false
  },

  // the value axis
  yAxis: {
    stops: [
      [0.1, '#55BF3B'], // green
      [0.8, '#DDDF0D'], // yellow
      [0.9, '#ec8239'], // orange
      [0.95, '#DF5353'] // red
    ],
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickAmount: 2,
    title: {
      y: 0,
      text: '',
      style: {
        fontSize: '20px'
      }
    },
    labels: {
      y: 16
    },
    min: 0,
    max: 0
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: 15,
        borderWidth: 0,
        useHTML: true
      }
    }
  },

  credits: {
    enabled: false
  },

  series: [
    {
      name: 'Count',
      data: [],
      radius: 100,
      dataLabels: {
        format:
          '<div style="text-align:center">' +
          '<span style="font-size:25px">{y}</span><br/>' +
          '</div>'
      }
    }
  ]
};
