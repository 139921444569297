import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getProbes } from '../../features/probesSlice';
import { BaNodeService } from '../../services/ba-node.service';
import LiveGauges from './LiveGauges';

const baNodeService = new BaNodeService();

const LiveGaugesWrapper = React.memo(
  ({ className, locations, age, time, currentMinute, installation, ...rest }) => {
    const probes = useSelector(getProbes);
    const [counts, setCounts] = useState([]);
    const [loadingCounts, setLoadingCounts] = useState(false);
    const [loadingLiveCounts, setLoadingLiveCounts] = useState(false);

    const updateArray = useCallback((arr, flatList) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] instanceof Array) {
          arr[i] = [...updateArray(arr[i], flatList)];
        } else {
          const idx = flatList.findIndex(fl => fl.location.id === arr[i].location.id);
          const temp = { ...arr[i] };
          temp.location.count = flatList[idx]?.location?.count || 0;
          arr[i] = { ...temp };
        }
      }
      return arr;
    }, []);

    const getWifiCountsFifteenMinuteInt = useCallback(async () => {
      let tempLocationsList = [...locations];
      const tempLocationsListFlat = [...tempLocationsList.flat(2)];
      const fifteenMinuteIntervals = await baNodeService.getWifiCountsFifteenMinuteInt(probes);

      // TODO: find a more efficient way to do this
      for (let i = 0; i < tempLocationsListFlat.length; i++) {
        const id = tempLocationsListFlat[i].location?.id;
        //TODO: need to figure out a better way to do this because we don't have 'locations' anymore
        if (tempLocationsListFlat[i].location) {
          tempLocationsListFlat[i].location.change = Math.round(
            fifteenMinuteIntervals?.[id]?.change || 0
          );
          tempLocationsListFlat[i].location.fifteenMinCount = Math.round(
            fifteenMinuteIntervals?.[id]?.countCurrent || 0
          );
          tempLocationsListFlat[i].location.previousFifteenMinCount = Math.round(
            fifteenMinuteIntervals?.[id]?.countPrevious || 0
          );
          tempLocationsListFlat[i].location.change = Math.round(
            fifteenMinuteIntervals?.[id]?.change || 0
          );
          tempLocationsListFlat[i].location.percentageChange = (
            (fifteenMinuteIntervals?.[id]?.percentageChange || 0) * 100
          ).toFixed(2);
        }
      }

      tempLocationsList = [...updateArray(tempLocationsList, tempLocationsListFlat)];
      setCounts([...tempLocationsList]);
      setLoadingCounts(false);
    }, [updateArray, locations, probes]);

    const getWifiCounts = useCallback(async () => {
      let tempLocationsList = [...locations];
      const tempLocationsListFlat = [...tempLocationsList.flat(2)];
      const locationIds = tempLocationsListFlat.map(l => l.location.id);
      const data = await baNodeService.getWifiCountByZoneList(locationIds, probes);
      if (!data || !Object.keys(data)?.length) return;

      // TODO: find a more efficient way to do this
      for (let i = 0; i < tempLocationsListFlat.length; i++) {
        const id = tempLocationsListFlat[i].location.id;
        const dataForLocation = data[id];
        tempLocationsListFlat[i].location.count = dataForLocation?.count || 0;
      }

      tempLocationsList = [...updateArray(tempLocationsList, tempLocationsListFlat)];
      setCounts([...tempLocationsList]);
    }, [updateArray, locations, probes]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (loadingLiveCounts) return;
      if (locations?.length > 0 && probes?.length > 0) {
        setLoadingLiveCounts(true);

        getWifiCounts().finally(() => {
          setLoadingLiveCounts(false);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, time, probes]);

    useEffect(() => {
      if (loadingCounts) return;
      if (locations?.length && probes?.length > 0) {
        setLoadingCounts(true);
        getWifiCountsFifteenMinuteInt();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, currentMinute, probes]);

    return <LiveGauges className={className} locations={counts} age={age} time={time} {...rest} />;
  }
);

LiveGaugesWrapper.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array,
  installation: PropTypes.any,
  age: PropTypes.number,
  time: PropTypes.number,
  currentMinute: PropTypes.number
};

export default LiveGaugesWrapper;
