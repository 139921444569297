import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  Installation: {
    backgroundColor: '#232D33'
  },
  Precinct: {
    backgroundColor: '#2B383F'
  },
  Zone: {
    backgroundColor: '#34444C'
  }
}));

const BtleUniques = ({ className, installation, locations, btleUniques, zoom, ...rest }) => {
  const classes = useStyles();
  const locationsFlat = locations?.flat(2) || [];

  const [btleUniquesVariableString, setBtleUniquesVariableString] = useState('');

  useEffect(() => {
    if (!Object.keys(btleUniques || {}).length) return;
    setBtleUniquesVariableString(btleUniques[locationsFlat.at(0)?.location?.id]?.lastPeriodName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btleUniques]);

  const formatCount = (val) => {
    if (!val || isNaN(parseInt(val))) return '';
    return val.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }
  const btleUniquesVariableRow =
    Object.keys(btleUniques || {}).length > 0 ? (
      <TableRow>
        <TableCell component="th" scope="row">
          {`Unique Visitors in last ${btleUniquesVariableString}`}
        </TableCell>
        {locationsFlat.map(location => (
          <TableCell key={location.location.id} className={classes[location.type]}>
            {formatCount(btleUniques[location.location.id]?.lastPeriod)}
          </TableCell>
        ))}
      </TableRow>
    ) : null;

  const btleUniquesDayRow =
    Object.keys(btleUniques || {}).length > 0 ? (
      <TableRow>
        <TableCell component="th" scope="row">
          {'Unique Visitors Today'}
        </TableCell>
        {locationsFlat.map(location => (
          <TableCell key={location.location.id} className={classes[location.type]}>
            {formatCount(btleUniques[location.location.id]?.total)}
          </TableCell>
        ))}
      </TableRow>
    ) : null;

  const btleUniquesTableHeadRow = (
    <TableRow>
      <TableCell />
      {locationsFlat.map(location => (
        <TableCell key={location.location.id} className={classes[location.type]}>
          {location.location.name}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Grid container spacing={1}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>{btleUniquesTableHeadRow}</TableHead>
          <TableBody>
            {btleUniquesVariableRow}
            {btleUniquesDayRow}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

BtleUniques.propTypes = {
  installation: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  btleUniques: PropTypes.object.isRequired,
  className: PropTypes.string,
  zoom: PropTypes.array
};

export default BtleUniques;
