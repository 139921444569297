import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    probes: [],
};

export const probesSlice = createSlice({
    name: 'probes',
    initialState,
    reducers: {
        updateProbes: (state, action) => {
            state.probes = [...state.probes, ...(action.payload || [])];
        },
    },
});

export const { updateProbes } = probesSlice.actions;

export const getProbes = (state) => state.probes.probes;
export const probesList = (state) => state.probes.probes;

export default probesSlice.reducer;