import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  UserPlus as UserPlusIcon
} from 'react-feather';

export const liveDashItem = {
  href: '/liveDash',
  icon: BarChartIcon,
  title: 'Live Dash',
  access: ['superuser', 'admin', 'manager', 'user']
};

export const navbarItems = [
  {
    href: '/event-dash',
    icon: BarChartIcon,
    title: 'Event Dash',
    access: ['superuser', 'admin', 'manager', 'user']
  },
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Settings',
    access: []
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login',
    access: []
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register',
    access: []
  }
];
