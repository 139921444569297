import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const useGet = () => {
  // create state variables
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(null);

  const runGet = async () => {
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    let unmounted = false;
    let source = axios.CancelToken.source();
    if (!url) return;
    setIsLoading(true);
    // clear old search
    setData(null);
    setError(null);
    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'text/plain',
          cancelToken: source.token,
          Authorization: `Bearer ${newToken}`
        }
      });
      if (!unmounted) {
        setIsLoading(false);
        setData(response.data);
        setUrl(null);
      }
    } catch (err) {
      if (!unmounted) {
        setIsLoading(false);
        setError(err);
        setUrl(null);
      }
    }
    return () => {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
    }
  }

  useEffect(() => {
    runGet().catch((err) => {
      setError(err);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return ([
    data,
    error,
    isLoading,
    setUrl
  ]);
};

export default useGet;
