import { LOCATIONS_URL, REST_BACKEND_URL, apiUrls, REST_V2_API_KEY } from '../config/constants';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

export class BaNodeService {
  constructor() {
    this.baseUrl = REST_BACKEND_URL;
    this.url = `${REST_BACKEND_URL}/${LOCATIONS_URL}`;
    this.countTodayUrl = apiUrls.countToday;
    this.fifteenMinuteCount = `${apiUrls.eventsUrlPy}/fifteen-minute-count`;

    this.lastXminAndToday = `${apiUrls.eventsUrlPy}/last-x-mins-and-today`;
    this.countToday = `${apiUrls.eventsUrlPy}/count-today`;
    this.eventCount = `${apiUrls.eventsUrl}/live-count-total`;
    this.eventUniquesCount = `${apiUrls.eventsUrl}/uniques-count-total`;
    this.eventsUrl = apiUrls.eventsUrl;
    this.eventsUrlPy = apiUrls.eventsUrlPy;
  }

  async getProbesByInstallationIds(installationIds) {
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    const { data } = await axios.get(
      `${REST_BACKEND_URL}/probes/by-installationids?installations=${installationIds.join(',')}`,
      {
        headers: {
          Authorization: `Bearer ${newToken}`,
          'x-api-key': REST_V2_API_KEY
        }
      }
    );

    return data;
  }

  async getWifiCountByZoneList(installationIds, probes) {
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    // first we get the list of Probes for Zones
    const intermediateProbes = [
      ...probes.filter(probe => installationIds.includes(probe.installationId)),
      ...probes.filter(probe => installationIds.includes(probe?.zone?.precinctId)),
      ...probes.filter(probe => installationIds.includes(probe?.zoneId))
    ];
    const probeIdSet = new Set(intermediateProbes.map(probe => probe.id));
    const probeIds = [...probeIdSet].join(',');
    const endPoint = `${this.countTodayUrl}?probe_ids=${probeIds}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });

    return data;
  }

  async getWifiCountsGraphDataByLocationList(installationIds, startTime, endTime, probes) {
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    // const probeIds = probes
    //   .filter(probe => installationIds.includes(probe.installationId))
    //   .map(probe => probe.id)
    //   .join(',');
    const intermediateProbes = [
      ...probes.filter(probe => installationIds.includes(probe.installationId)),
      ...probes.filter(probe => installationIds.includes(probe?.zone?.precinctId)),
      ...probes.filter(probe => installationIds.includes(probe?.zoneId))
    ];
    const probeIdSet = new Set(intermediateProbes.map(probe => probe.id));
    const probeIds = [...probeIdSet].join(',');
    const endPoint = `${this.countToday}?probe_ids=${probeIds}&from_and_to=${startTime},${endTime}`;
    // const endPoint = `${this.url}/wificount/between/${startTime}/and/${endTime}?locations=${idStr}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });
    return data;
  }

  async getWifiCountsFifteenMinuteInt(probes) {
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    // first we get the list of Probes for Zones

    const probeIds = probes.map(probe => probe.id);
    const idStr = probeIds.join(',');
    const endPoint = `${this.fifteenMinuteCount}?probe_ids=${idStr}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });

    return data;
  }

  async getBtleUniquesGraphDataByLocationList(ids, startTime, endTime) {
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    const idStr = ids.join(',');
    const endPoint = `${this.url}/btleuniques/between/${startTime}/and/${endTime}?locations=${idStr}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`
      }
    });
    return data;
  }

  async getUniquesByZoneList(installationIds, timeFrame, probes) {
    // async getUniquesByZoneList(installationIds, probes) {
    // locationId is the uuid we want to see
    // timeSince is the time in milliseonds
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    const intermediateProbes = [
      ...probes.filter(probe => installationIds.includes(probe.installationId)),
      ...probes.filter(probe => installationIds.includes(probe?.zone?.precinctId)),
      ...probes.filter(probe => installationIds.includes(probe?.zoneId))
    ];
    const probeIdSet = new Set(intermediateProbes.map(probe => probe.id));
    const probeIds = [...probeIdSet].join(',');
    // const zonesStr = zoneIds.join(',');
    const endPoint = `${this.lastXminAndToday}?probe_ids=${probeIds}&time_frame=${timeFrame}`;
    // const endPoint = `${this.lastXminAndToday}?probeIds=${probeIds}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });
    return data;
  }

  async getWifiCountsForEvent(installationIds, fromAndToList, probes) {
    // locationId is the uuid we want to see
    // timeSince is the time in milliseonds
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    const probeIds = probes
      .filter(probe => installationIds.includes(probe.installationId))
      .map(probe => probe.id)
      .join(',');
    const fromAndToStr = encodeURIComponent(JSON.stringify(fromAndToList));
    const endPoint = `${this.eventCount}?probeIds=${probeIds}&fromAndTo=${fromAndToStr}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });
    return data;
  }

  async getBtleCounts(installationIds, fromAndToList, probes) {
    // locationId is the uuid we want to see
    // timeSince is the time in milliseonds
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    const intermediateProbes = [
      ...probes.filter(probe => installationIds.includes(probe.installationId)),
      ...probes.filter(probe => installationIds.includes(probe?.zone?.precinctId)),
      ...probes.filter(probe => installationIds.includes(probe?.zoneId))
    ];
    const probeIdSet = new Set(intermediateProbes.map(probe => probe.id));
    const probeIds = [...probeIdSet].join(',');
    let fromAndToStr = `from_and_to=${fromAndToList.join(',')}`;
    if (fromAndToStr.at(-1) === '&') {
      fromAndToStr = fromAndToStr.substring(0, fromAndToStr.length - 1);
    }
    const endPoint = `${this.eventsUrlPy}/uniques?probe_ids=${probeIds}&${fromAndToStr}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });
    return data;
  }

  async getBtleCountsForEvent(installationIds, fromAndToList, probes) {
    // locationId is the uuid we want to see
    // timeSince is the time in milliseonds
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    const intermediateProbes = [
      ...probes.filter(probe => installationIds.includes(probe.installationId)),
      ...probes.filter(probe => installationIds.includes(probe?.zone?.precinctId)),
      ...probes.filter(probe => installationIds.includes(probe?.zoneId))
    ];
    const probeIdSet = new Set(intermediateProbes.map(probe => probe.id));
    const probeIds = [...probeIdSet].join(',');
    const fromAndToStr = encodeURIComponent(JSON.stringify(fromAndToList));
    const endPoint = `${this.eventUniquesCount}?probeIds=${probeIds}&fromAndTo=${fromAndToStr}`;
    const { data } = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${newToken}`,
        'x-api-key': REST_V2_API_KEY
      }
    });
    return data;
  }
}

export const baNodeService = new BaNodeService();
