import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  uniquesPer: {
    marginBottom: '10px'
  },
  uniquesCount: {
    fontSize: '1.5em',
    paddingLeft: '20px'
  },
  redText: {
    fontSize: '1.5em',
    color: '#FF1E05'
  },
  greenText: {
    fontSize: '1.5em',
    color: 'green'
  },
  fontLarge: {
    fontSize: '1.5em'
  },
  paxIngress: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  }
}));
const FifteenMinuteSummary = ({
  location,
  uniqueCount,
  fifteenMinuteCount,
  previousFifteenMinuteCount,
  percentageChange,
  gridSize
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                <Box sx={{ display: 'inline-flex' }}>
                  <Box className={classes.paxIngress}>
                    <div>Ingress PAX / m:</div>
                  </Box>
                  <Tooltip title="Calculated as an average over 15 mins" arrow>
                    <InfoIcon style={{ width: '15px', marginLeft: '5px' }} />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell
                key={location.location.id}
                className={`${classes.fontLarge} ${classes[location.type]}`}
              >
                {uniqueCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15 min:</TableCell>
              <TableCell
                key={location.location.id}
                className={`${classes[location.type]} ${
                  fifteenMinuteCount < 0 ? classes.redText : classes.greenText
                }`}
              >
                {fifteenMinuteCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Prev. 15 min:</TableCell>
              <TableCell
                key={location.location.id}
                className={`${classes[location.type]} ${
                  previousFifteenMinuteCount < 0 ? classes.redText : classes.greenText
                }`}
              >
                {previousFifteenMinuteCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Change:</TableCell>
              <TableCell
                key={location.location.id}
                className={`${classes[location.type]} ${
                  percentageChange < 0 ? classes.redText : classes.greenText
                }`}
              >
                {percentageChange}
                {percentageChange !== undefined ? '%' : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

FifteenMinuteSummary.propTypes = {
  location: PropTypes.instanceOf(Object),
  uniqueCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fifteenMinuteCount: PropTypes.number,
  previousFifteenMinuteCount: PropTypes.number,
  percentageChange: PropTypes.string,
  gridSize: PropTypes.number
};

FifteenMinuteSummary.defaultProps = {
  gridSize: 12
};

export default FifteenMinuteSummary;
