import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, CircularProgress, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getProbes } from '../../features/probesSlice';
import TimeSeriesZoomable from '../highCharts/TimeSeriesZoomable';
import { BaNodeService } from '../../services/ba-node.service';
import { formatCountsData, getChunkedTime } from './minute-graph.helpers';
// import { calcFromTime } from '../../utils/time.helpers';

const baNodeService = new BaNodeService();

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const MinuteGraphs = React.memo(
  ({ className, installation, locations, currentLocations, age, time, setZoom, ...rest }) => {
    const probes = useSelector(getProbes);
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [locationsObj, setLocationsObj] = useState(null);
    const [fromTime, setFromTime] = useState(null);
    const [allLocationsData, setAllLocationsData] = useState({});
    const [data, setData] = useState({});
    // const [timeList, setTimeList] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    // const runningGet = useRef(false);
    const [runningGet, setRunningGet] = useState(false);
    const dataArray = Object.entries(data).map(([id, loc]) => ({ ...loc, id }));

    const setCurrentLocation = () => {
      if (locations?.length) {
        const mappedLocation = locations.flat(2).reduce(
          (locs, location) => ({
            ...locs,
            [location.location.id]: {
              ...location,
              latestMinute: 0
            } // locs[id]
          }),
          {}
        );
        setLocationsObj({ ...mappedLocation });
      }
    };

    const getWifiCountData = async () => {
      if (!installation?.id || !probes.length) return;
      const locationIds = locations
        .flat(2)
        .map(l => l?.location?.id)
        .filter(l => l !== undefined);
      if (!locationIds.length || !probes.length) {
        setRunningGet(false);
        return;
      }
      let calcFromTime = fromTime;
      // first we check to see if we have any data and then we just start at datStart instead
      if (Object.keys(data).length === 0) calcFromTime = getDayStart();
      // if this is the first time loading, we would have multiple hours to get data from, so lets' do this in chunks
      const chunkedTime = getChunkedTime(calcFromTime, currentTime, 3);
      let dataSet = {};
      let counts = {};
      let newFromTimeToSet = null;
      for (const [newFromTime, newToTime] of chunkedTime) {
        const result = await baNodeService.getWifiCountsGraphDataByLocationList(
          locationIds,
          newFromTime,
          newToTime,
          probes
        );
        for (const id of locationIds) {
          if (!(id in result)) continue
          if (!counts[id]) {
            counts[id] = result[id];
          } else {
            counts[id].counts = [...(counts?.[id]?.counts || []), ...result[id].counts].sort(
              (a, b) => a.timestamp - b.timestamp
            );
          }
        }

        // need to update the keys in the btleUniquesData
        newFromTimeToSet = newToTime;
      }
      // we pass the time so we can reset the graph if we have a new day
      dataSet = formatCountsData(counts, data, time);
      setAllLocationsData({ ...dataSet });
      setData({ ...dataSet });
      setFromTime(newFromTimeToSet);
      setRunningGet(false);
    };

    useEffect(() => {
      if (currentLocations?.length) {
        // // if we first loaded, then we don't do anything
        // if (currentLocations.length === locations.length) return;
        const tempData = {};
        const keys = Object.keys(currentLocations);
        const currentLocationIds = currentLocations
          .flat(2)
          .reduce((locs, location) => [...locs, location.location.id], []);
        keys.forEach(key => {
          if (currentLocationIds.indexOf(key) > -1) tempData[key] = allLocationsData[key];
        });
        setData({ ...tempData });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocations]);

    useEffect(() => {
      if (locations?.length) setCurrentLocation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    useEffect(() => {
      if (currentTime && !runningGet && probes.length) {
        setRunningGet(true);
        getWifiCountData()
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            setRunningGet(false);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime, probes]);

    useEffect(() => {
      if (runningGet) return;
      // const newTimeList = [...timeList, time];
      const timeToSet = new Date(time).setSeconds(0, 0);
      setCurrentTime(timeToSet);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    const getDayStart = () => {
      const now = new Date().getTime();
      let dayStart = new Date().setHours(installation.dayStart || 0, 0, 0, 0);
      if (dayStart > now) dayStart -= 86400000;
      return dayStart;
    };

    useEffect(() => {
      const dayStart = getDayStart();
      setFromTime(dayStart);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Count Today" />
        <CardContent>
          {dataArray.length > 0 ? (
            <TimeSeriesZoomable
              name={dataArray[0].name}
              count={dataArray}
              capacity={dataArray[0].capacity}
              setZoom={setZoom}
            />
          ) : (
            <CircularProgress />
          )}
        </CardContent>
      </Card>
    );
  }
);

MinuteGraphs.propTypes = {
  className: PropTypes.string,
  installation: PropTypes.object,
  locations: PropTypes.array,
  currentLocations: PropTypes.array,
  age: PropTypes.number,
  time: PropTypes.number,
  setZoom: PropTypes.func
};

export default MinuteGraphs;
