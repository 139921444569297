import { REST_BACKEND_URL, REST_V2_BACKEND_PYTHON_URL, REST_V2_API_KEY } from '../config/constants';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
// import camelcaseKeys from 'camelcase-keys';

class TenantService {
  constructor() {
    this.baseUrl = REST_BACKEND_URL;
    this.url = `${REST_BACKEND_URL}/installations`;
  }

  async addTenant(name) {
    const endPoint = `${this.baseUrl}/tenants`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.post(endPoint, { name }, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async getTenants() {
    const endPoint = `${this.baseUrl}/tenants`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getTenant(tenantId) {
    const endPoint = `${this.baseUrl}/tenants/${tenantId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async getTenantInstallations(tenantId) {
    const endPoint = `${this.baseUrl}/tenants/${tenantId}/installations`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async getTenantInstallation(tenantId, installationId) {
    const endPoint = `${REST_V2_BACKEND_PYTHON_URL}/tenants/${tenantId}/installations/${installationId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`,
          'x-api-key': REST_V2_API_KEY
        }
      });
      // return camelcaseKeys(data, { deep: true });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async getTenantPrecincts(tenantId, installationId) {
    const endPoint = `${REST_V2_BACKEND_PYTHON_URL}/tenants/${tenantId}/installations/${installationId}/precincts`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`,
          'x-api-key': REST_V2_API_KEY
        }
      });
      // return camelcaseKeys(data, { deep: true });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async deleteTenant(tenantId) {
    const endPoint = `${this.baseUrl}/tenants/${tenantId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.delete(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async updateTenant(tenantId, tenantData) {
    const endPoint = `${this.baseUrl}/tenants/${tenantId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.put(endPoint, { ...tenantData }, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
}

export const tenantService = new TenantService();
