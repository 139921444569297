import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
}));

const Clock = ({ className, time, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Typography variant="h1">
          {time}
        </Typography>
      </CardContent>
    </Card>
  );
};

Clock.propTypes = {
  className: PropTypes.string,
  time: PropTypes.string
};

export default Clock;
