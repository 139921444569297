import React from 'react';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(() => ({
  root: {},
  box: {
    background: 'url(/static/images/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
}));

const WelcomeView = () => {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title="Welcome"
      height="100%"
    >
      <Box
        className={classes.box}
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        Welcome
      </Box>
    </Page>

  );
};

export default WelcomeView;
