import PropTypes from 'prop-types';
import { Navigate, useRoutes } from 'react-router-dom';
import routes from '../routes';
import MainLayout from '../layouts/MainLayout';
import LoginView from '../views/auth/LoginView';
import RegisterView from '../views/auth/RegisterView';
import NotFoundView from '../views/errors/NotFoundView';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDefaultNavigateTo } from '../features/tenantSlice';

const MainRoutes = ({ role, view, enabled, pathname }) => {
  const defaultNavigateTo = useSelector(getDefaultNavigateTo);

  const defaultMainRoute = {
    path: '/',
    element: !role ? <MainLayout /> : <Navigate to={defaultNavigateTo} />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  };
  const newRoutes = [...routes(role, view, enabled, pathname), defaultMainRoute];
  const routing = useRoutes(newRoutes);

  return <>{routing}</>;
};

MainRoutes.propTypes = {
  role: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired
};

export default MainRoutes;
