import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, Grid, makeStyles } from '@material-ui/core';
import DatePicker, { DateObject } from 'react-multi-date-picker';
// import DatePanel from "react-multi-date-picker/plugins/date_panel";
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
// import Button from "react-multi-date-picker/components/button";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateLabel: {
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  marginY: {
    margin: '10px 0'
  },
  marginX: {
    margin: '0 10px'
  }
}));

const MultiDateSelect = React.memo(
  ({
    className,
    selectedDates,
    setSelectedDates,
    layout,
    setLayout,
    dayStart,
    headerText,
    ...rest
  }) => {
    const datePickerRef = useRef();
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const classes = useStyles();


    const onDateChange = e => {
      if (e.length === 2) {
        setMinDate('');
        setMaxDate('');
        datePickerRef.current.closeCalendar();
      } else {
        let miDate = new DateObject(e[0]);
        let maDate = new DateObject(e[0]);
        setMinDate(miDate.subtract(6, 'days'));
        setMaxDate(maDate.add(6, 'days'));
      }
      setSelectedDates(e);
    };

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={2}>
          <div className={classes.marginY}>
            <DatePicker
              ref={datePickerRef}
              className="bg-dark"
              range
              rangeHover
              minDate={minDate}
              maxDate={maxDate}
              render={<MaterialButton variant="outlined">Select dates</MaterialButton>}
              value={selectedDates}
              onChange={onDateChange}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <div className={classes.wrapper}>
            <div>Selected range:</div>
            {selectedDates.length === 2 ? (
              <div className={classes.dateLabel}>
                {new Date(selectedDates[0]).toLocaleDateString()}
                <div className={classes.marginX}>-</div>
                {new Date(selectedDates[1]).toLocaleDateString()}
              </div>
            ) : null }
          </div>
        </Grid>
      </Grid>
    );
  }
);

MultiDateSelect.propTypes = {
  className: PropTypes.string,
  selectedDates: PropTypes.array,
  setSelectedDates: PropTypes.func,
  layout: PropTypes.string,
  setLayout: PropTypes.func,
  dayStart: PropTypes.number,
  headerText: PropTypes.string
};

export default MultiDateSelect;
