import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Gauge from '../highCharts/Gauge';
import { getBtleUniques } from '../../features/btle-uniques/btleSlice';
import { useSelector } from 'react-redux';
import FifteenMinuteSummary from '../shared/FifteenMinuteSummary';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  uniquesPer: {
    marginBottom: '10px'
  },
  uniquesCount: {
    fontSize: '1.5em',
    paddingLeft: '20px'
  },
  redText: {
    fontSize: '1.5em',
    color: '#FF1E05'
  },
  greenText: {
    fontSize: '1.5em',
    color: 'green'
  }
}));

const LiveGauge = ({ className, location, age, time, ...rest }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [uniqueCount, setUniqueCount] = useState(null);
  const [percentageChange, setPercentageChange] = useState(null);
  const [fifteenMinuteCount, setFifteenMinuteCount] = useState(null);
  const [previousFifteenMinuteCount, setPreviousFifteenMinuteCount] = useState(null);
  const btleUniques = useSelector(getBtleUniques);

  useEffect(() => {
    // if (getCountError) console.log(getCountError);
    if (location?.location?.count) setCount(location?.location?.count);

    if (location?.location?.fifteenMinCount !== null) {
      setFifteenMinuteCount(location?.location?.fifteenMinCount);
    }
    if (location?.location?.previousFifteenMinCount !== null) {
      setPreviousFifteenMinuteCount(location?.location?.previousFifteenMinCount);
    }
    if (location?.location?.previousFifteenMinCount !== null) {
      setPercentageChange(location?.location?.percentageChange);
    }
  }, [location]);

  // useEffect(() => {
  //   if (fifteenMinuteCount !== null && previousFifteenMinuteCount !== null) {
  //     const change = (fifteenMinuteCount - previousFifteenMinuteCount) / previousFifteenMinuteCount;
  //     setPercentageChange((change * 100).toFixed(2));
  //   }
  // }, [fifteenMinuteCount, previousFifteenMinuteCount]);

  useEffect(() => {
    // console.log('====== btleUniqueValue inside of LiveGauge', btleUniques);
    const uniques = btleUniques?.btleUniques;
    if (Object.keys(uniques || {})?.length) {
      let foundUniqueCount = uniques[location.location?.id]?.count;
      if (foundUniqueCount === undefined) foundUniqueCount = '';
      setUniqueCount(foundUniqueCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btleUniques]);

  return (
    <>
      <Gauge
        className={clsx(classes.cardheader, className)}
        title={`${location?.type}: ${location?.location?.name}`}
        name={null}
        count={count}
        capacity={location?.location?.capacity}
        area={location?.location?.area}
        {...rest}
      />
      <FifteenMinuteSummary
        location={location}
        fifteenMinuteCount={fifteenMinuteCount}
        uniqueCount={uniqueCount}
        previousFifteenMinuteCount={previousFifteenMinuteCount}
        percentageChange={percentageChange}
      />
    </>
  );
};

LiveGauge.propTypes = {
  className: PropTypes.string,
  location: PropTypes.instanceOf(Object),
  age: PropTypes.number,
  time: PropTypes.number,
  btleUniqueValue: PropTypes.number
};

export default LiveGauge;
