import { addHours } from 'date-fns';

export const getChunkedTime = (fromTime, toTime, chunkSize = 2) => {
  let fromDate = new Date(fromTime);
  const toDate = new Date(toTime);
  const fromHours = fromDate.getHours();
  const toHours = toDate.getHours();
  const chunkedTime = [];
  //now let's chunk it into sections of 2 hour blocks
  let difference = toHours - fromHours;
  if (difference <= chunkSize) return [[fromTime, toTime]];
  while (difference > chunkSize) {
    chunkedTime.push([fromDate.getTime(), addHours(fromDate, chunkSize).getTime()]);
    difference -= chunkSize;
    fromDate = addHours(fromDate, chunkSize);
  }
  // need this to get the last set of data for the current 'chunkSize' time frame
  chunkedTime.push([fromDate.getTime(), addHours(fromDate, chunkSize).getTime()]);
  return chunkedTime;
};

export const formatWifiUniquesData = (wifiCounts, locationsObj) => {
  let reducedEntries = {};
  //TODO: fix up this reduce to do the minute graph - or check to see if we actually need to do anything that's similar to this
  // const location = locationsObj.current[key]
  const reducedData = wifiCounts.reduce((minuteData, curr) => {
    const key = curr.locationId;
    const location = locationsObj[key];
    if (!location) return minuteData;
    locationsObj[key].latestMinute = curr.to;
    return {
      ...minuteData,
      [key]: {
        name: `${location.type}: ${location.location.name}`,
        color:
          location.location?.graphColor?.css?.backgroundColor ||
          location.location?.graphColor ||
          null,
        data: curr.count.reduce((minutes, second) => {
          // average second data over the minute
          const minute = Math.floor(second.timestamp / 60000) * 60000; // beginning of minute
          return {
            ...minutes,
            [minute]: {
              count: (minutes[minute]?.count ?? 0) + 1,
              sum: (minutes[minute]?.sum ?? 0) + second.count
            }
          };
        }, {})
      }
    };
  }, {});
  const entries = Object.entries(reducedData);
  reducedEntries = {
    ...reducedEntries,
    ...entries.reduce(
      (newMins, [loc, locData]) => ({
        ...newMins,
        [loc]: {
          ...locData,
          data: Object.entries(locData.data).map(([minute, minuteCount]) => [
            +minute,
            Math.round(minuteCount.sum / minuteCount.count)
          ]) // newMins[loc].data
        } // newMins[loc]
      }),
      {}
    )
  };

  return reducedEntries;
};

// the expected input is an object keyed on the location
export const formatCountsData = (wifiCounts, currData, currTime) => {
  //TODO: fix up this reduce to do the minute graph - or check to see if we actually need to do anything that's similar to this
  // const location = locationsObj.current[key]
  // let's check the current time and reset the currData.[key].data if we have a new day
  const timeFromGraph = currData?.[Object.keys(currData)[0]]?.data?.[0]?.[0];
  let isNewDay = false;
  if (timeFromGraph) {
    const timeFromGraphDate = new Date(timeFromGraph);
    const currTimeDate = new Date(currTime);
    if (timeFromGraphDate.getDate() !== currTimeDate.getDate()) {
      isNewDay = currTimeDate.getHours() >= 3;
    }
  }
  const tableData = isNewDay ? {} : { ...currData };
  const keys = Object.keys(wifiCounts);
  const obj = {};
  for (const key of keys) {
    // const location = locationsObj[key];
    // if (!location) continue;
    const curr = wifiCounts[key];
    // locationsObj[key].latestMinute = curr.timestamp;
    const dataSet = new Set(curr.counts || []);
    obj[key] = {
      // name: `${location.type}: ${location.location.name}`,
      name: curr.name,
      color: curr.graphColor || null,
      data: [
        ...(tableData?.[key]?.data || []),
        ...Array.from(dataSet).map(count => [+count.timestamp, count.count])
      ]
    };
  }
  return obj;
};
