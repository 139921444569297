export const calcFromTime = (installation, time, fromTime) => {
  // running on first render
  const currentTime = new Date();
  let newFromTime = null;
  if (time && installation?.dayStart) {
    if (!fromTime) {
      let dayStart = new Date(time).setHours(installation.dayStart || 0, 0, 0, 0);
      if (dayStart > time) dayStart -= 86400000; // e.g. if dayStart is 3 am and time is 2 am
      newFromTime = dayStart;
    } else {
      newFromTime = currentTime.setHours(currentTime.getHours(), 0, 0, 0);
    }
  }
  return newFromTime;
};
