import React, { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Modal,
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import useGet from '../../hooks/useGet';
import Clock from '../../components/Clock';
import MultiDaySelector from '../../components/MultiDaySelector';
import MultiDateSelect from '../../components/MultiDateSelect';
import MinuteGraphs from './MinuteGraphs';
import DailyUniques from './DailyUniques';
import ReportsTable from '../../components/ReportsTable';
import Title from './Title';
import { apiUrls } from '../../config/constants';
import { getProfile } from '../../features/authSlice';
import { baNodeService } from '../../services/ba-node.service';
import { updateProbes, probesList } from '../../features/probesSlice';

const tenantsUrl = apiUrls['tenants'];

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    background: theme.palette.background.gradient
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  }
}));

const DashboardView = () => {
  const dispatch = useDispatch();
  const [installation, setInstallation] = useState({ precincts: {} });
  const [selectedPrecinct, setSelectedPrecinct] = useState({
    id: 'none',
    name: 'All',
    zones: {}
  });
  const [selectedZone, setSelectedZone] = useState({
    id: 'none',
    name: 'none'
  });
  const [subtitle, setSubtitle] = useState('');
  const [time] = useState(new Date());
  const [layout, setLayout] = useState('overlay');
  const [selectedDates, setSelectedDates] = useState([]);

  const [reportsSelectedDates, setReportsSelectedDates] = useState([]);
  const profile = useSelector(getProfile);
  const probes = useSelector(probesList);

  const [installationUrl] = useState(
    `${tenantsUrl}/${profile.tenantId}/installations/${profile.installationId}`
  );
  const [locations, setLocations] = useState([]);
  const [title, setTitle] = useState(null);
  const [getTenantData, getTenantError, getTenantIsLoading, getTenantSetUrl] = useGet();
  const [
    getInstallationData,
    getInstallationError,
    getInstallationIsLoading,
    getInstallationSetUrl
  ] = useGet();
  const [getPrecinctsData, getPrecinctsError, getPrecinctsIsLoading, getPrecinctsSetUrl] = useGet();
  const [getZonesData, getZonesError, getZonesIsLoading, getZonesSetUrl] = useGet();
  const classes = useStyles();

  const getProbes = useCallback(async () => {
    if (!installation) return;
    const data = await baNodeService.getProbesByInstallationIds([installation?.id]);
    dispatch(updateProbes(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installation]);

  useEffect(() => {
    getTenantSetUrl(`${tenantsUrl}/${profile.tenantId}`);
    getInstallationSetUrl(installationUrl);
    getPrecinctsSetUrl(`${installationUrl}/precincts`);
    getZonesSetUrl(`${installationUrl}/zones`);
  }, [
    getTenantSetUrl,
    getInstallationSetUrl,
    getPrecinctsSetUrl,
    getZonesSetUrl,
    profile.tenantId,
    installationUrl
  ]);

  useEffect(() => {
    if (getTenantError) console.log(getTenantError.response.statusText);
    if (getInstallationError) console.log(getInstallationError.response.statusText);
  }, [getTenantError, getInstallationError]);

  useEffect(() => {
    if (!getTenantIsLoading && getTenantData) {
      setSubtitle(getTenantData.name);
    }
  }, [getTenantIsLoading, getTenantData]);

  useEffect(() => {
    if (
      !getInstallationIsLoading &&
      getInstallationData &&
      !getPrecinctsIsLoading &&
      getPrecinctsData &&
      !getZonesIsLoading &&
      getZonesData
    ) {
      const precinctArray = Object.values(getPrecinctsData);
      const zoneArray = Object.values(getZonesData).filter(zone => zone.show);
      setInstallation({
        ...getInstallationData,
        capacity: zoneArray.reduce((result, zone) => result + parseFloat(zone.capacity), 0),
        area: zoneArray.reduce((result, zone) => result + parseFloat(zone.area), 0),
        precincts: precinctArray.reduce(
          (precinctResult, precinct) => ({
            ...precinctResult,
            [precinct.id]: {
              ...precinct,
              capacity: zoneArray
                .filter(zone => zone.precinctId === precinct.id)
                .reduce((result, zone) => result + parseFloat(zone.capacity), 0),
              area: zoneArray
                .filter(zone => zone.precinctId === precinct.id)
                .reduce((result, zone) => result + parseFloat(zone.area), 0),
              zones: zoneArray
                .filter(zone => zone.precinctId === precinct.id)
                .reduce(
                  (zoneResult, zone) => ({
                    ...zoneResult,
                    [zone.id]: zone
                  }),
                  {}
                )
            }
          }),
          {}
        ),
        loaded: true
      });
      if (precinctArray.length === 1) {
        const precinctZones = zoneArray.filter(zone => zone.precinctId === precinctArray[0].id);
        setSelectedPrecinct({
          id: precinctArray[0].id,
          name: precinctArray[0].name,
          area: precinctArray[0].area,
          capacity: precinctArray[0].capacity,
          zones: precinctZones.reduce(
            (zones, zone) => ({
              ...zones,
              [zone.id]: zone
            }),
            {}
          )
        });
        setTitle(precinctZones.length === 1 ? precinctZones[0].name : precinctArray[0].name);
      } else {
        setSelectedPrecinct({
          id: 'none',
          name: 'none',
          zones: {}
        });
        setTitle(installation.name);
      }
    }
  }, [
    getInstallationIsLoading,
    getInstallationData,
    getPrecinctsIsLoading,
    getPrecinctsData,
    getZonesIsLoading,
    getZonesData,
    installation.name
  ]);

  useEffect(() => {
    if (installation.loaded) {
      if (!probes.length) getProbes().catch(e => console.error(e));
      setLocations([{ type: 'Installation', location: installation }]);
    } // endif (installation.loaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installation, selectedPrecinct, selectedZone]);

  useEffect(() => {
    setSelectedZone(
      selectedPrecinct.id !== 'none' &&
        selectedPrecinct.id !== 'all' &&
        selectedPrecinct.id !== 'allZones' &&
        selectedPrecinct.id !== 'allPrecincts' &&
        Object.keys(selectedPrecinct.zones).length === 1
        ? {
            name: Object.values(selectedPrecinct.zones)[0].name,
            id: Object.values(selectedPrecinct.zones)[0].id,
            area: Object.values(selectedPrecinct.zones)[0].area,
            capacity: Object.values(selectedPrecinct.zones)[0].capacity
          }
        : {
            name: 'none',
            id: 'none'
          }
    );
  }, [selectedPrecinct]);

  if (!profile.installationId || getInstallationError) {
    return <Navigate to="/account" />;
  } else {
    return (
      <Page className={classes.root} title="Multiday Count">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item lg={8} md={6} sm={7} xs={12}>
              <Title
                title="Multiday counter"
                subtitle={subtitle}
                installation={installation}
                selectedPrecinct={selectedPrecinct}
                setSelectedPrecinct={setSelectedPrecinct}
                selectedZone={selectedZone}
                setSelectedZone={setSelectedZone}
                setTitle={setTitle}
                isLoading={getInstallationIsLoading || getPrecinctsIsLoading || getZonesIsLoading}
                className={classes.card}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={5} xs={12}>
              <Clock time={time.toLocaleString()} className={classes.card} />
            </Grid>
            <Grid item xs={12}>
              <Card className={clsx(classes.root)}>
                <CardHeader title="Reports" />
                <CardContent className={classes.cardContent}>
                  <MultiDateSelect
                    className={classes.card}
                    selectedDates={reportsSelectedDates}
                    setSelectedDates={setReportsSelectedDates}
                    layout={layout}
                    setLayout={setLayout}
                    dayStart={installation?.dayStart || 0}
                  />
                  <ReportsTable className={classes.card} selectedDates={reportsSelectedDates} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <MultiDaySelector
                className={classes.card}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                layout={layout}
                setLayout={setLayout}
                dayStart={installation?.dayStart || 0}
              />
            </Grid>
            {selectedDates.length && locations.length ? (
              <>
                <Grid item xs={12}>
                  <MinuteGraphs
                    className={classes.card}
                    title={title}
                    installation={installation}
                    locations={locations}
                    selectedDates={selectedDates}
                    layout={layout}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DailyUniques
                    className={classes.card}
                    title={title}
                    installation={installation}
                    locations={locations}
                    selectedDates={selectedDates}
                    layout={layout}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
        <Modal open={Boolean(getTenantError || getInstallationError)}>
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {getTenantError && 'getTenantError'}
              {getInstallationError && 'getInstallationError'}
              {getPrecinctsError && 'getPrecinctsError'}
              {getZonesError && 'getZonesError'}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {getTenantError && getTenantError.response.statusText}
              {getInstallationError && getInstallationError.response.statusText}
              {getPrecinctsError && getPrecinctsError.response.statusText}
              {getZonesError && getZonesError.response.statusText}
            </Typography>
          </Box>
        </Modal>
      </Page>
    );
  }
};

export default DashboardView;
