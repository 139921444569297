import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  makeStyles
} from '@material-ui/core';
import MultipleDatesPicker from '@randex/material-ui-multiple-dates-picker';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const MultiDaySelector = React.memo(
  ({ className, selectedDates, setSelectedDates, layout, setLayout, dayStart, ...rest }) => {
    const classes = useStyles();
    const [openDates, setOpenDates] = useState(false);

    const onSubmitHandler = dates => {
      setSelectedDates(
        dates
          .sort((a, b) => a.getTime() - b.getTime())
          .map(date => date.setHours(dayStart, 0, 0, 0))
      );
      setOpenDates(false);
    };

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Multidate selection" />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={2}>
              <Button variant="outlined" onClick={() => setOpenDates(!openDates)}>
                Click here to Select Dates
              </Button>
              <MultipleDatesPicker
                open={openDates}
                selectedDates={selectedDates.map(date => new Date(date))}
                onCancel={() => setOpenDates(false)}
                onSubmit={onSubmitHandler}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              {selectedDates.map(date => (
                <Chip key={date} label={new Date(date).toLocaleDateString()} />
              ))}
            </Grid>
            <Grid item xs={12} md={1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Layout</FormLabel>
                <RadioGroup name="layout" value={layout} onChange={e => setLayout(e.target.value)}>
                  <FormControlLabel value="overlay" control={<Radio />} label="Overlay" />
                  <FormControlLabel value="consecutive" control={<Radio />} label="Consecutive" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

MultiDaySelector.propTypes = {
  className: PropTypes.string,
  selectedDates: PropTypes.array,
  setSelectedDates: PropTypes.func,
  layout: PropTypes.string,
  setLayout: PropTypes.func,
  dayStart: PropTypes.number
};

export default MultiDaySelector;
