import StyledDiv from './StyledDiv';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const StyledCenteredLoader = () => (
  <div style={{ display: 'flex' }}>
    <div style={{ display: 'flex-row' }}>
      <StyledDiv>Loading, please wait...</StyledDiv>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    </div>
  </div>
);

export default StyledCenteredLoader;
