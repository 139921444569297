import React, { useState, useEffect } from 'react';
// Import Highcharts
import Highcharts from 'highcharts/highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { staticOptions } from './partials/gauge.options';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const Gauge = ({ title, name, count, capacity }) => {
  const [options, setOptions] = useState(staticOptions);
  const theme = useTheme();

  useEffect(() => {
    staticOptions.chart.backgroundColor = theme.palette.background.highCharts;
    staticOptions.title.style = { color: theme.palette.text.primary };
    staticOptions.series[0].dataLabels.color = theme.palette.text.primary;
  }, [theme.palette]);

  useEffect(() => {
    setOptions({
      ...staticOptions,
      title: {
        ...staticOptions.title,
        text: title
      },
      yAxis: {
        ...staticOptions.yaxis,
        text: name,
        max: capacity
      },
      series: [
        {
          ...staticOptions.series[0],
          data: [count]
        }
      ]
    });
  }, [count, title, name, capacity]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

Gauge.propTypes = {
  title: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
  count: PropTypes.number,
  name: PropTypes.string
};

Gauge.defaultProps = {
  name: '',
  count: 0
};

export default Gauge;
