import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { DateObject } from 'react-multi-date-picker';
import { reportsService } from '../services/reports.service';
import { getProfile } from '../features/authSlice';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  Installation: {
    backgroundColor: '#232D33'
  },
  Precinct: {
    backgroundColor: '#2B383F'
  },
  Zone: {
    backgroundColor: '#34444C'
  }
}));

const ReportsTable = ({ className, selectedDates, ...rest }) => {
  const classes = useStyles();
  const [datequery, setDateQuery] = useState('');
  const [downloadingReport, setDownloadingReport] = useState(false);
  const beProfile = useSelector(getProfile);

  // TODO: need to change endpoint
  const reports = [
    {
      name: 'Live and Unqiue counts',
      endpoint: ''
    }
  ];

  const downloadReport = async endpoint => {
    setDownloadingReport(true);

    try {
      const response = await reportsService.downloadReport(
        endpoint,
        beProfile.installationId,
        datequery
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.style.display = 'none';
      fileLink.href = url;
      fileLink.target = '_blank';
      fileLink.download = response.headers
        .get('content-disposition')
        ?.split('filename=')?.[1]
        ?.replace(/"/g, '');
      // fileLink.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]?.replace(/"/g, ''))
      document.body.appendChild(fileLink);
      fileLink.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file: ', error);
    } finally {
      setDownloadingReport(false);
    }
  };

  useEffect(() => {
    if (selectedDates?.length < 2) return;
    const allDatesInRange = [];
    // let's assume we only have 2 dates
    const dateDiff = selectedDates[0].daysLeft - selectedDates[1].daysLeft;
    for (let i = 0; i <= dateDiff; i++) {
      let obj = new DateObject(selectedDates[0]).add(i, 'days');
      allDatesInRange.push(obj.format('YYYY-MM-DD'));
    }
    setDateQuery(allDatesInRange);
  }, [selectedDates]);

  const reportsTableRows = reports.map(report => (
    <TableRow>
      <TableCell component="th" scope="row">
        {report.name}
      </TableCell>
      <TableCell key={report.name}>
        <Button
          variant="outlined"
          disabled={downloadingReport || selectedDates.length < 2}
          onClick={() => downloadReport(report.endpoint)}
        >
          {downloadingReport ? 'Please wait...' : 'Download'}
        </Button>
      </TableCell>
    </TableRow>
  ));

  const reportsTableHeadRow = (
    <TableRow>
      <TableCell key="name-header">Name</TableCell>
      <TableCell key="download-row">Download</TableCell>
    </TableRow>
  );

  return (
    <Grid container spacing={3}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>{reportsTableHeadRow}</TableHead>
          <TableBody>{reportsTableRows}</TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

ReportsTable.propTypes = {
  className: PropTypes.string,
  selectedDates: PropTypes.array
};

export default ReportsTable;
