import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import NavItem from './NavItem';
import { getIsInstallationEnabled } from '../../../features/tenantSlice';
import { liveDashItem, navbarItems } from './nav-bar-items';
import { updateProfile, getProfile } from '../../../features/authSlice';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const dispatch = useDispatch();
  const isInstallationEnabled = useSelector(getIsInstallationEnabled);
  const classes = useStyles();
  const location = useLocation();
  const firebase = useFirebase();
  const beProfile = useSelector(getProfile);
  const fb = useSelector(state => state.firebase);
  const [menuItems, setMenuItems] = useState([...navbarItems]);

  const logoutHandler = () => {
    dispatch(updateProfile({}));
    firebase.logout();
  };

  useEffect(() => {
    if (isInstallationEnabled) {
      const currentMenuItems = [...menuItems];
      setMenuItems([liveDashItem, ...currentMenuItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstallationEnabled]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        {fb.auth.photoURL || fb.profile.photoURL ? (
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            src={fb.profile.photoURL || fb.auth.photoURL}
            to="/account"
          />
        ) : (
          <Avatar className={classes.avatar} component={RouterLink} to="/account">
            {beProfile.initials}
          </Avatar>
        )}
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {fb.auth.displayName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {beProfile.email}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {menuItems
            .filter(item => item.access.includes(beProfile.role))
            .map(item => (
              <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
            ))}
          <ListItem className={classes.item}>
            <Button className={classes.button} onClick={logoutHandler}>
              Sign Out
            </Button>
          </ListItem>
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark" />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
