import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  btleUniques: {}
};

export const btleUniquesSlice = createSlice({
  name: 'btleUniques',
  initialState,
  reducers: {
    updateBtleUniques: (state, action) => {
      state.btleUniques = { ...state.btleUniques, ...(action.payload?.unique || {}) };
    }
  }
});

export const { updateBtleUniques } = btleUniquesSlice.actions;

// TODO: need to get this in the component, at the moment, not coming through
export const getBtleUniques = state => state.btleUniques;

export default btleUniquesSlice.reducer;
