import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import CombinedLiveDashboardView from 'src/views/combinedLiveDashboard/DashboardView';
import CombinedMultidayDashboardView from './views/combinedMultidayDashboard/MultiDayDashboardView';
import NotFoundView from 'src/views/errors/NotFoundView';
import WelcomeView from 'src/views/welcome/WelcomeView';

const Dashboards = {
  // heatmap: <DashboardView />,
  combinedDash: <CombinedLiveDashboardView />
};
const dashboardRoutes = {
  liveView: 'liveView',
  individualView: 'individual',
  combinedDash: 'liveDash'
};

const getChildRoutes = (role, view, enabled, pathname) => {
  const rolesList = ['pending', 'admin', 'superuser'];
  const liveDashList = ['user', 'manager', 'admin', 'superuser'];
  const navigateTo = !enabled ? 'event-dash' : dashboardRoutes[view] || 'dashboard';
  let routesList = [
    { path: '', element: <Navigate to={navigateTo} replace={true} /> },
    {
      path: 'dashboard',
      element: rolesList.includes(role) ? <WelcomeView /> : Dashboards[view] || <WelcomeView />
    }
  ];

  const fallbackRoutes = [
    { path: '404', element: <NotFoundView /> },
    { path: '*', element: <Navigate to="/404" /> }
  ]

  const dashboardRoutesList = [
    {
      path: 'event-dash',
      element: <CombinedMultidayDashboardView />,
      class: pathname === '/event-dash' ? 'active' : ''
    }
  ];

  const managerRoutesList = [
  ];

  if (liveDashList.includes(role) && enabled) {
    routesList.push({
      path: 'liveDash',
      element: <CombinedLiveDashboardView />,
      class: pathname === '/liveDash' ? 'active' : ''
    });
  }

  switch (role) {
    case 'pending':
      break;
    case 'user':
      routesList = [...routesList, ...dashboardRoutesList];
      break;
    case 'manager':
      routesList = [...routesList, ...dashboardRoutesList, ...managerRoutesList];
      break;
    case 'admin':
    case 'superuser':
      routesList = [
        ...routesList,
        ...dashboardRoutesList,
        ...managerRoutesList,
      ];
      break;
    default:
      routesList = [...routesList.slice(2)];
  }
  routesList = [...routesList, ...fallbackRoutes];
  return routesList;
};

const routes = (role, view, enabled) => [
  {
    path: '',
    element: role ? <DashboardLayout /> : <Navigate to="/login" />,
    children: getChildRoutes(role, view, enabled)
  }
];

export default routes;
