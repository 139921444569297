import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardHeader, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getBtleUniques } from '../../features/btle-uniques/btleSlice';
import FifteenMinuteSummary from '../shared/FifteenMinuteSummary';
// import useGet from '../../hooks/useGet';

const useStyles = makeStyles(theme => ({
  cardheader: {
    fontSize: '200%'
  },
  // example how to use theme breakpoints
  headerSummary: {
    width: '95%!important',
    [theme.breakpoints.up('md')]: {
      width: '45%!important'
    },
    [theme.breakpoints.up('xl')]: {
      width: '30%!important'
    }
  }
}));

const LiveGaugeHeader = ({ className, location, age, time, isLoading, ...rest }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  //TODO: this is the exact same as what is in LiveGauge.js, figure out how to factor this out
  const [uniqueCount, setUniqueCount] = useState(null);
  const btleUniques = useSelector(getBtleUniques);

  useEffect(() => {
    // if (getCountError) console.log(getCountError);
    if (location?.location?.count) {
      setCount(Math.round(location?.location?.count || 0));
    }
  }, [location]);

  //TODO: this is the exact same as what is in LiveGauge.js, figure out how to factor this out
  useEffect(() => {
    const uniques = btleUniques?.btleUniques;
    // console.log('====== btleUniqueValue inside of LiveGauge', btleUniques, uniques);

    if (Object.keys(uniques || {})?.length) {
      let foundUniqueCount = uniques[location.location?.id]?.count;
      if (foundUniqueCount === undefined) foundUniqueCount = '';
      setUniqueCount(foundUniqueCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btleUniques]);

  return (
    <>
      {location?.location && (
        <>
          <CardHeader
            classes={{ title: classes.cardheader }}
            title={`Live Count for ${location.location.name}: ${count || ''}`}
            {...rest}
          />
          <div style={{ marginLeft: '20px' }} className={classes.headerSummary}>
            <FifteenMinuteSummary
              location={location}
              fifteenMinuteCount={location.location.fifteenMinCount}
              uniqueCount={uniqueCount}
              previousFifteenMinuteCount={location.location.previousFifteenMinCount}
              percentageChange={location.location.percentageChange}
            />
          </div>
        </>
      )}
    </>
  );
};

LiveGaugeHeader.propTypes = {
  location: PropTypes.instanceOf(Object),
  age: PropTypes.number,
  time: PropTypes.number,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

LiveGaugeHeader.defaultProps = {
  location: {},
  className: '',
  isLoading: false
};

export default LiveGaugeHeader;
