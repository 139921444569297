import { REST_BACKEND_URL } from '../config/constants';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

class InstallationService {
  constructor() {
    this.baseUrl = REST_BACKEND_URL;
    this.url = `${REST_BACKEND_URL}/installations`;
  }

  async addInstallation(tenantId, name) {
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.post(
        this.url,
        { name, tenantId },
        {
          headers: {
            Authorization: `Bearer ${newToken}`
          }
        }
      );
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async updateInstallation(installationId, data) {
    const endPoint = `${this.url}/${installationId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const response = await axios.patch(endPoint, data, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return response;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async getInstallations() {
    const endPoint = `${this.url}/all`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async deleteInstallation(installationId) {
    const endPoint = `${this.url}/${installationId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const response = await axios.delete(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return response;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async getInstallation(tenantId, installationId) {
    const endPoint = `${this.baseUrl}/tenants/${tenantId}/installations/${installationId}`;
    try {
      const user = getAuth().currentUser;
      const newToken = await user?.getIdToken();
      const { data } = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
}

export const installationService = new InstallationService();
