import React, { useEffect, useState } from 'react';
import { CardHeader, CardContent, Card } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getProbes } from '../../features/probesSlice';
import BtleUniques from './BtleUniques';
import { BaNodeService } from '../../services/ba-node.service';

const baNodeService = new BaNodeService();

const BtleUniquesWrapper = React.memo(
  ({ classes, className, installation, locations, time, zoom, ...rest }) => {
    const [btleUniques, setBtleUniques] = useState({});
    const [loadingBtleUniques, setLoadingBtleUniques] = useState(false);
    const probes = useSelector(getProbes);

    const getBtleUniques = async () => {
      if (!installation?.id || !probes.length) return;
      const timeFrame = installation?.dashUniqueSample;
      // const installationIds = [installation?.id];
      const locationIds = locations
        .flat(2)
        .map(l => l?.location?.id)
        .filter(l => l !== undefined);
      if (!locationIds.length || !probes.length) {
        return;
      }
      console.log('======== probes', probes, locationIds);
      const uniques = await baNodeService.getUniquesByZoneList(locationIds, timeFrame, probes);
      setBtleUniques({ ...uniques });
      setLoadingBtleUniques(false);
    };

    useEffect(() => {
      if (loadingBtleUniques) return;
      if (locations?.length) {
        // const locationsFlat = locations?.flat(2) || [];
        // console.log('======== getting the uniques in the btle wrapper thingy', locationsFlat);
        setLoadingBtleUniques(true);
        getBtleUniques()
          .catch(e => console.error('error getting the uniques', e))
          .finally(() => setLoadingBtleUniques(false));
      }
      // eslint-disable-next-line
    }, [locations, time, probes]);

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Unique Visitor Count" />
        <CardContent>
          <BtleUniques
            className={classes.card}
            installation={installation}
            locations={locations}
            btleUniques={btleUniques}
          />
        </CardContent>
      </Card>
    );
  }
);

BtleUniquesWrapper.propTypes = {
  classes: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  installation: PropTypes.object,
  locations: PropTypes.array,
  time: PropTypes.number,
  zoom: PropTypes.array
};

export default BtleUniquesWrapper;
