import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { REPORTS_API, REST_V2_API_KEY } from '../config/constants';

class ReportsService {
  constructor() {
    this.baseUrl = REPORTS_API;
    this.url = `${REPORTS_API}/reports`;
  }

  async downloadReport(endPoint, installationId, days) {
    const dateQuery = days ? days.map(d => `days=${d}`).join('&') : '';
    let url = `${this.url}`;
    if (endPoint) {
      url = `${url}/${endPoint}`;
    }
    url = `${url}/${installationId}`;
    if (dateQuery) {
      url = `${url}?${dateQuery}`;
    }
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${newToken}`,
          'x-api-key': REST_V2_API_KEY
        }
      });
      return response;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async generateReport(tenantId, installationId) {
    const endPoint = `${this.baseUrl}/reports`;
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    try {
      const { data } = await axios.post(
        endPoint,
        {
          tenantId,
          installationId
        },
        {
          headers: {
            Authorization: `Bearer ${newToken}`
          }
        }
      );
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async addToQueue(tenantId, installationId, email) {
    const endPoint = `${this.baseUrl}/reports/add-to-queue`;
    const user = getAuth().currentUser;
    const newToken = await user?.getIdToken();
    try {
      const { data } = await axios.post(
        endPoint,
        {
          tenantId,
          installationId,
          email
        },
        {
          headers: {
            Authorization: `Bearer ${newToken}`
          }
        }
      );
      return data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
}

export const reportsService = new ReportsService();
