import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { REST_V2_API_KEY, REST_BACKEND_URL } from '../config/constants';

class ProfileService {
    async getProfile(firebaseId) {
        const endPoint = `${REST_BACKEND_URL}/users/${firebaseId}/profile`;
        const user = getAuth().currentUser;
        const newToken = await user?.getIdToken();
        const { data } = await axios.get(endPoint, {
          headers: {
            Authorization: `Bearer ${newToken}`,
            'x-api-key': REST_V2_API_KEY
          }
        });
        return data;
    }
}

export const profileService = new ProfileService();